var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "text-end", attrs: { sm: "4" } },
            [
              _c("v-icon", { attrs: { color: "grey" } }, [_vm._v("school")]),
              _c("label", [
                _vm._v(" Doutorado - " + _vm._s(_vm.doctoratesStudents))
              ])
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { sm: "4" } },
            [
              _c("v-icon", [_vm._v("school")]),
              _c("label", [
                _vm._v(" Mestrado - " + _vm._s(_vm.mastersStudents))
              ])
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "4" } },
            [
              _c("v-icon", { attrs: { color: "black" } }, [_vm._v("school")]),
              _c("label", [
                _vm._v(
                  " Total - " +
                    _vm._s(_vm.mastersStudents + _vm.doctoratesStudents)
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }