<template>
    <v-card outlined>
      
        <v-row justify="center" align="center">
          <v-col sm="4" class="text-end">
            <v-icon color="grey">school</v-icon>
            <label> Doutorado - {{ doctoratesStudents }}</label>
          </v-col>
          <v-col sm="4" class="text-center">
            <v-icon>school</v-icon>
            <label> Mestrado - {{ mastersStudents }}</label>
          </v-col>
          <v-col sm="4">
            <v-icon color="black">school</v-icon>
            <label> Total - {{ mastersStudents + doctoratesStudents }}</label>
          </v-col>
        </v-row>
    
    </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      title: "Estudantes",
    };
  },

  created() {
    if (this.mastersStudents == 0) this.getAsyncTotalMasterStudents();
    if (this.doctoratesStudents == 0) this.getAsyncTotalDoctoralStudents();
  },

  computed: {
    ...mapState("numberStudents", ["mastersStudents", "doctoratesStudents"]),
  },

  methods: {
    ...mapActions("numberStudents", [
      "ActionGetTotalMasterStudents",
      "ActionGetTotalDoctoralStudents",
    ]),

    async getAsyncTotalMasterStudents() {
      try {
        await this.ActionGetTotalMasterStudents();
      } catch (err) {
        console.error(err);
      }
    },

    async getAsyncTotalDoctoralStudents() {
      try {
        await this.ActionGetTotalDoctoralStudents();
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
</style>