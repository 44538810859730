<template>
  <v-row justify="start" align="center" dense>

    <v-col cols="12" sm="12" md="6">
      <h3>Estudantes</h3>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <NumberStudents></NumberStudents>
    </v-col>


    <v-col cols="12" sm="12" md="3" class="">
      <v-text-field
        outlined
        dense
        single-line
        v-model="search"
        append-icon="search"
        placeholder="digite o nome ou matricula do aluno"
      >
      </v-text-field>
    </v-col>

    <v-col cols="12">
      <v-container>
        <Legenda></Legenda>
      </v-container>
    </v-col>
    <br />

    <v-col cols="12" class="">
      <v-data-table
        :headers="headers"
        :items="students"
        :items-per-page="students.length"
        :search="search"
        :loading="showLoading"
        hide-default-footer
        no-data-text="Ainda foram encontrados alunos"
        loading-text="Buscando estudantes... pode demorar até um minuto"
      >
        <template v-slot:item="props">
          <tr @click="showStudent(props.item)">
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.course }}</td>
            <td class="text-xs-left">
              {{ props.item.period }}
              <label
                v-if="props.item.couseBreakes > 0"
                class="overline text-monospace"
                >[{{ props.item.couseBreakes }}]</label
              >
            </td>

            <td>{{ props.item.occurrences }}</td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.englishStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.englishColor"
                    :size="avatarSize"
                    v-on="on"
                  >
                    <v-icon color="black" small>{{
                      props.item.englishSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.creditsStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.creditsColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.creditsSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.orientatorStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.orientatorColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.orientatorSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.pedStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.pedColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.pedSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.crStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.crColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{ props.item.crSpan }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>

            <td class="text-xs-left">
              <v-tooltip left>
                <span>{{ props.item.examStatus }}</span>
                <template v-slot:activator="{ on }">
                  <v-avatar
                    v-bind:class="props.item.examColor"
                    v-on="on"
                    :size="avatarSize"
                  >
                    <v-icon color="black" small>{{
                      props.item.examSpan
                    }}</v-icon>
                  </v-avatar>
                </template>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Legenda from "@/components/common/Legenda";
import NumberStudents from "@/components/common/number_students/NumberStudents";

export default {
  name: "Students",
  components: {
    Legenda,
    NumberStudents,
  },
  data() {
    return {
      showLoading: false,
      pages: 0,
      page: 0,
      total: 0,
      size: 25,
      avatarSize: "35",
      search: "",
      legends: [
        { id: 0, color: "green", message: "OK" },
        { id: 1, color: "light-blue darken-1", message: "Opcional" },
        { id: 2, color: "amber lighten-2", message: "Informativo" },
        { id: 3, color: "orange darken-2", message: "Atenção" },
        { id: 4, color: "red darken-1", message: "Problema" },
      ],
      headers: [
        { sortable: true, text: "RA", value: "academicCode", align: "left" },
        { sortable: true, text: "Aluno", value: "name", align: "left" },
        { sortable: true, text: "Curso", value: "course", align: "left" },
        { sortable: true, text: "Período", value: "period", align: "left" },
        {
          sortable: true,
          text: "Ocorrências",
          value: "occurrences",
          align: "left",
        },
        { sortable: true, text: "Inglês", value: "englishCode", align: "left" },
        {
          sortable: true,
          text: "Créditos",
          value: "creditsCode",
          align: "left",
        },
        {
          sortable: true,
          text: "Orientação",
          value: "orientatorCode",
          align: "left",
        },
        { sortable: true, text: "PED", value: "pedCode", align: "left" },
        { sortable: true, text: "CR", value: "crCode", align: "left" },
        { sortable: true, text: "Exame", value: "examCode", align: "left" },
      ],
      items: [],
    };
  },

  created() {
    if (this.students.length == 0) this.getAsyncActivesStudents();
  },

  methods: {
    ...mapActions("students", ["ActionGetActiveStudents"]),

    async getAsyncActivesStudents() {
      this.showLoading = true;
      try {
        await this.ActionGetActiveStudents();
      } catch (err) {
        console.error(err);
      } finally {
        this.showLoading = false;
      }
    },

    showStudent(student) {
      this.$router.push({
        name: "Student",
        params: { academic_code: student.academicCode, student: student },
        append: true,
      });
    },
  },

  computed: {
    ...mapState("students", ["students"]),
  },
};
</script>


<style scoped>
td {
  cursor: pointer;
}

.md2 {
  max-width: none;
}

fieldset {
  max-width: 60%;
  margin: auto;
}
</style>