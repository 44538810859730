var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c("v-col", { attrs: { cols: "12", sm: "12", md: "6" } }, [
        _c("h3", [_vm._v("Estudantes")])
      ]),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12", md: "6" } },
        [_c("NumberStudents")],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12", md: "3" } },
        [
          _c("v-text-field", {
            attrs: {
              outlined: "",
              dense: "",
              "single-line": "",
              "append-icon": "search",
              placeholder: "digite o nome ou matricula do aluno"
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [_c("v-container", [_c("Legenda")], 1)],
        1
      ),
      _c("br"),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.students,
              "items-per-page": _vm.students.length,
              search: _vm.search,
              loading: _vm.showLoading,
              "hide-default-footer": "",
              "no-data-text": "Ainda foram encontrados alunos",
              "loading-text":
                "Buscando estudantes... pode demorar até um minuto"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showStudent(props.item)
                          }
                        }
                      },
                      [
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.academicCode))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.name))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.course))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(" " + _vm._s(props.item.period) + " "),
                          props.item.couseBreakes > 0
                            ? _c(
                                "label",
                                { staticClass: "overline text-monospace" },
                                [
                                  _vm._v(
                                    "[" + _vm._s(props.item.couseBreakes) + "]"
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c("td", [_vm._v(_vm._s(props.item.occurrences))]),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.englishColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.englishSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.englishStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.creditsColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.creditsSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.creditsStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class:
                                                  props.item.orientatorColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.orientatorSpan
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.orientatorStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.pedColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.pedSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.pedStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.crColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.crSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.crStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                class: props.item.examColor,
                                                attrs: { size: _vm.avatarSize }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "black",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.examSpan)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.item.examStatus))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }